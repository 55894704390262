
import React from "react"
import Container from '@mui/material/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  //faTwitter,
  faFacebookSquare,
  //faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import {makeStyles} from "@mui/styles";
import Image from "./image"

// スタイルの記述をする
const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
  },

  baseStyles: {
    width: '100%',
    backgroundColor: 'gray',
    margin: 0,
    paddingTop: 20,
    position: "absolute",
    bottom: 0,
  },
  site: {
    flexGrow: 1,
    color: "white",
  },

  sns: {
    color: "white",
    textDecoration: "none",
    margin: 15,
  },

  copyright: {
    color: "white",
    textAlign: "center"
  },
}));

export default function Footer({breakpoints}) {

  const classes = useStyles();

  return (
    
    <footer>
      <div className={classes.baseStyles}>
        <Container> 
          <div className={classes.container}>
            <div className={classes.site}  style = {{fontSize: breakpoints?.md ? 14 : 18}}>
              <div style = { {paddingBottom: 20} } >
                <a href= {`https://www.stansystem.co.jp`} target="_blank" rel="noreferrer" style = {{textDecoration: "none", color: "white",fontSize: breakpoints?.md ? 18 : 22}}>
                <div style = {{paddingBottom: 5, maxWidth: breakpoints?.md ? 120 : 180}}><Image filename="logo3.png" /></div>
                スタンシステム株式会社
                </a>
              </div>
              <div style = {{paddingBottom: 10}} >
                〒770-0941<br/>
                　徳島県徳島市万代町3丁目5-4 近藤ビル
              </div>            
            </div>
            <div>
              <a href="https://ja-jp.facebook.com/stansystem"　className={classes.sns} style = {{fontSize: breakpoints?.md ? 24 : 28} } target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} />
                <span className="sr-only">Facebook</span>
              </a>
            </div>
          </div>
        </Container>
        <div className={classes.copyright} style = {{fontSize: breakpoints?.md ? 10 : 16} } >Copyright(C) Stan Systems Corporation All Rights Reserved.</div>
      </div>
      
    </footer>
  );
}